.imageContainer {
    background-color: var(--clr-beige);
    width: 45rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 0px 20px;
    min-height: 500px;
}

.xlImage {
    width: 38rem;
    max-height: 30rem;
    object-fit: contain;
}

.largeImage {
    width: 300px;
    margin: 2rem;
    border: 0.7rem solid var(--clr-beige);
    box-shadow: var(--light-shadow);
}

.smallImage {
    width: 92%;
    padding-bottom: 10px;
}

@media screen and (max-width: 780px) {
    .imageContainer {
        background-color: var(--clr-beige);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        min-height: 200px;
    }
    
    .xlImage {
        width: 100%;
        max-height: 30rem;
        object-fit: contain;
    }

    p {
        font-size: 1rem;
    }
}