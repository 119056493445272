@import "./style.css";

.horizontalFlexContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    flex: 1 1 auto;
}

.verticalFlexContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0px 30px;
    flex: 1 1 auto;
}

.imageGridContainer {
    column-width: calc(300px + 10vw);
    padding: 30px;
    margin: 0;
}

.textContainer {
    width: 50%;
    padding: 2rem;
    margin: 2rem;
    background-color: var(--clr-light-pink);
    border: 0.7rem solid var(--clr-beige);
}

.cardContainer {
    background-color: var(--clr-beige);
    display: flex;
    flex-direction: column;
    flex: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    margin: 1.8rem;
    padding-top: 16px;
}

.cardTextContainer {
    margin-bottom: 15px;
    width: 90%;
}

.slideContainer {
    display: flex;
    flex-direction: column;
    flex: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    margin: 1.8rem;
    padding-top: 16px;
}

.titleContainer {
    align-self: flex-start;
    padding: 1rem;
}

.titleContainer > h2 {
    font-size: 2.5rem;
    color: var(--clr-light-pink);
    text-shadow: var(--text-shadow)
}

.projectContainer {
    background-color: var(--clr-beige);
    padding: 20px;
    margin: 20px 0px;
}

.projectContainer > p {
    font-size: 1rem;
}

/* styles for smaller (900- px width screens) */
@media screen and (max-width: 1020px) {
    .horizontalFlexContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 0px 30px;
    }
}

@media screen and (max-width: 780px) {
    p {   
        text-align: left;
    }
    
    .slideContainer {
        max-width: 80%;
        width: 100%;
        margin-top: 0.1rem;
    }
}