/* Fonts */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* Variables */

:root {
  --clr-dark-pink: #E5BABF;
  --clr-light-pink: #FCF1EF;
  --clr-beige: #E7DBC9;
  --clr-light-grey: #CDD3D6;
  --clr-dark-grey: #5E616B;
  --font-primary: "Poppins", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  --text-shadow: 2px 2px 5px var(--clr-dark-grey);
  --max-width: 1170px;
  --fixed-width: 620px;
}

/* global styles */

.app {
  font-family: var(--font-primary);
  background-color: var(--clr-dark-pink);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-dark-grey);
  text-align: justify;
  font-size: 1.2rem;
}

.italic-text {
  font-style: italic;
  font-weight: bold;
}

h1 {
  text-shadow: var(--text-shadow);
}

h2 {
  font-size: 1.1rem;
}
h3 {
  font-size: 0.8rem;
  color: var(--clr-dark-grey);
}
h4 {
  font-size: 0.7rem;
  color: var(--clr-dark-grey);
}

button {
  padding: 10px 10px 16px;
  height: 100%;
  background-color: var(--clr-light-pink);
  font-size: 1.5rem;
  color: var(--clr-dark-grey);
  border-color: transparent; 
  border-radius: 2px;
  cursor: pointer;
  opacity: 50%;
}

button:hover {
  background-color: var(--clr-light-pink);
  box-shadow: var(--light-shadow);
}